var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form",
    { attrs: { id: "registerForm", action: "", method: "post" } },
    [
      _c("div", { staticClass: "form-group" }, [
        _c("input", {
          staticClass: "form-control form-control-danger",
          attrs: {
            placeholder: _vm.$t("login.enter_email"),
            type: "email",
            name: "email"
          }
        })
      ]),
      _vm._v(" "),
      _vm._m(0),
      _vm._v(" "),
      _vm._m(1),
      _vm._v(" "),
      _c("base-button", { staticClass: "btn btn-login btn-full" }, [
        _vm._v(_vm._s(_vm.$t("login.register")))
      ])
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "form-group" }, [
      _c("input", {
        staticClass: "form-control form-control-danger",
        attrs: {
          id: "password",
          type: "password",
          placeholder: "Enter Password",
          name: "password"
        }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "form-group" }, [
      _c("input", {
        staticClass: "form-control form-control-danger",
        attrs: {
          type: "password",
          placeholder: "Retype Password",
          name: "password_confirmation"
        }
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }
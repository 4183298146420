var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "customer-create main-content" }, [
    _c(
      "form",
      {
        attrs: { action: "" },
        on: {
          submit: function($event) {
            $event.preventDefault()
            return _vm.submitCustomerData($event)
          }
        }
      },
      [
        _c("div", { staticClass: "page-header" }, [
          _c("h3", { staticClass: "page-title" }, [
            _vm._v(
              _vm._s(
                _vm.isEdit
                  ? _vm.$t("customers.edit_customer")
                  : _vm.$t("customers.new_customer")
              )
            )
          ]),
          _vm._v(" "),
          _c("ol", { staticClass: "breadcrumb" }, [
            _c(
              "li",
              { staticClass: "breadcrumb-item" },
              [
                _c(
                  "router-link",
                  {
                    attrs: { slot: "item-title", to: "/admin/dashboard" },
                    slot: "item-title"
                  },
                  [_vm._v(_vm._s(_vm.$t("general.home")))]
                )
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "li",
              { staticClass: "breadcrumb-item" },
              [
                _c(
                  "router-link",
                  {
                    attrs: { slot: "item-title", to: "/admin/customers" },
                    slot: "item-title"
                  },
                  [_vm._v(_vm._s(_vm.$tc("customers.customer", 2)))]
                )
              ],
              1
            ),
            _vm._v(" "),
            _c("li", { staticClass: "breadcrumb-item" }, [
              _vm._v(
                _vm._s(
                  _vm.isEdit
                    ? _vm.$t("customers.edit_customer")
                    : _vm.$t("customers.new_customer")
                )
              )
            ])
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "page-actions header-button-container" },
            [
              _c(
                "base-button",
                {
                  attrs: {
                    loading: _vm.isLoading,
                    disabled: _vm.isLoading,
                    tabindex: 23,
                    icon: "save",
                    color: "theme",
                    type: "submit"
                  }
                },
                [
                  _vm._v(
                    "\n          " +
                      _vm._s(
                        _vm.isEdit
                          ? _vm.$t("customers.update_customer")
                          : _vm.$t("customers.save_customer")
                      ) +
                      "\n        "
                  )
                ]
              )
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "customer-card card" }, [
          _c("div", { staticClass: "card-body" }, [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "section-title col-sm-2" }, [
                _vm._v(_vm._s(_vm.$t("customers.basic_info")))
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-sm-5" }, [
                _c(
                  "div",
                  { staticClass: "form-group" },
                  [
                    _c("label", { staticClass: "form-label" }, [
                      _vm._v(_vm._s(_vm.$t("customers.display_name")))
                    ]),
                    _c("span", { staticClass: "text-danger" }, [_vm._v(" *")]),
                    _vm._v(" "),
                    _c("base-input", {
                      attrs: {
                        invalid: _vm.$v.formData.name.$error,
                        focus: "",
                        type: "text",
                        name: "name",
                        "tab-index": "1"
                      },
                      on: {
                        input: function($event) {
                          return _vm.$v.formData.name.$touch()
                        }
                      },
                      model: {
                        value: _vm.formData.name,
                        callback: function($$v) {
                          _vm.$set(_vm.formData, "name", $$v)
                        },
                        expression: "formData.name"
                      }
                    }),
                    _vm._v(" "),
                    _vm.$v.formData.name.$error
                      ? _c("div", [
                          !_vm.$v.formData.name.required
                            ? _c("span", { staticClass: "text-danger" }, [
                                _vm._v(_vm._s(_vm.$tc("validation.required")))
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          !_vm.$v.formData.name.minLength
                            ? _c("span", { staticClass: "text-danger" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$tc(
                                        "validation.name_min_length",
                                        _vm.$v.formData.name.$params.minLength
                                          .min,
                                        {
                                          count:
                                            _vm.$v.formData.name.$params
                                              .minLength.min
                                        }
                                      )
                                    ) +
                                    " "
                                )
                              ])
                            : _vm._e()
                        ])
                      : _vm._e()
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "form-group" },
                  [
                    _c("label", { staticClass: "form-label" }, [
                      _vm._v(_vm._s(_vm.$t("customers.email")))
                    ]),
                    _vm._v(" "),
                    _c("base-input", {
                      attrs: {
                        invalid: _vm.$v.formData.email.$error,
                        type: "text",
                        name: "email",
                        "tab-index": "3"
                      },
                      on: {
                        input: function($event) {
                          return _vm.$v.formData.email.$touch()
                        }
                      },
                      model: {
                        value: _vm.formData.email,
                        callback: function($$v) {
                          _vm.$set(
                            _vm.formData,
                            "email",
                            typeof $$v === "string" ? $$v.trim() : $$v
                          )
                        },
                        expression: "formData.email"
                      }
                    }),
                    _vm._v(" "),
                    _vm.$v.formData.email.$error
                      ? _c("div", [
                          !_vm.$v.formData.email.email
                            ? _c("span", { staticClass: "text-danger" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$tc("validation.email_incorrect")
                                    ) +
                                    " "
                                )
                              ])
                            : _vm._e()
                        ])
                      : _vm._e()
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "form-group" },
                  [
                    _c("label", { staticClass: "form-label" }, [
                      _vm._v(_vm._s(_vm.$t("customers.primary_currency")))
                    ]),
                    _vm._v(" "),
                    _c("base-select", {
                      attrs: {
                        options: _vm.currencies,
                        "custom-label": _vm.currencyNameWithCode,
                        "allow-empty": false,
                        searchable: true,
                        "show-labels": false,
                        tabindex: 5,
                        placeholder: _vm.$t("customers.select_currency"),
                        label: "name",
                        "track-by": "id"
                      },
                      model: {
                        value: _vm.currency,
                        callback: function($$v) {
                          _vm.currency = $$v
                        },
                        expression: "currency"
                      }
                    })
                  ],
                  1
                )
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-sm-5" }, [
                _c(
                  "div",
                  { staticClass: "form-group" },
                  [
                    _c("label", { staticClass: "form-label" }, [
                      _vm._v(_vm._s(_vm.$t("customers.primary_contact_name")))
                    ]),
                    _vm._v(" "),
                    _c("base-input", {
                      attrs: {
                        label: _vm.$t("customers.contact_name"),
                        type: "text",
                        "tab-index": "2"
                      },
                      model: {
                        value: _vm.formData.contact_name,
                        callback: function($$v) {
                          _vm.$set(
                            _vm.formData,
                            "contact_name",
                            typeof $$v === "string" ? $$v.trim() : $$v
                          )
                        },
                        expression: "formData.contact_name"
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "form-group" },
                  [
                    _c("label", { staticClass: "form-label" }, [
                      _vm._v(_vm._s(_vm.$t("customers.phone")))
                    ]),
                    _vm._v(" "),
                    _c("base-input", {
                      attrs: { type: "text", name: "phone", "tab-index": "4" },
                      model: {
                        value: _vm.formData.phone,
                        callback: function($$v) {
                          _vm.$set(
                            _vm.formData,
                            "phone",
                            typeof $$v === "string" ? $$v.trim() : $$v
                          )
                        },
                        expression: "formData.phone"
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "form-group" },
                  [
                    _c("label", { staticClass: "form-label" }, [
                      _vm._v(_vm._s(_vm.$t("customers.website")))
                    ]),
                    _vm._v(" "),
                    _c("base-input", {
                      attrs: {
                        invalid: _vm.$v.formData.website.$error,
                        type: "url"
                      },
                      on: {
                        input: function($event) {
                          return _vm.$v.formData.website.$touch()
                        }
                      },
                      model: {
                        value: _vm.formData.website,
                        callback: function($$v) {
                          _vm.$set(_vm.formData, "website", $$v)
                        },
                        expression: "formData.website"
                      }
                    }),
                    _vm._v(" "),
                    _vm.$v.formData.website.$error
                      ? _c("div", [
                          !_vm.$v.formData.website.url
                            ? _c("span", { staticClass: "text-danger" }, [
                                _vm._v(
                                  _vm._s(_vm.$tc("validation.invalid_url"))
                                )
                              ])
                            : _vm._e()
                        ])
                      : _vm._e()
                  ],
                  1
                )
              ])
            ]),
            _vm._v(" "),
            _c("hr"),
            _vm._v(" "),
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "section-title col-sm-2" }, [
                _vm._v(_vm._s(_vm.$t("customers.billing_address")))
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-sm-5" }, [
                _c(
                  "div",
                  { staticClass: "form-group" },
                  [
                    _c("label", { staticClass: "form-label" }, [
                      _vm._v(_vm._s(_vm.$t("customers.name")))
                    ]),
                    _vm._v(" "),
                    _c("base-input", {
                      attrs: {
                        type: "text",
                        name: "address_name",
                        "tab-index": "7"
                      },
                      model: {
                        value: _vm.billing.name,
                        callback: function($$v) {
                          _vm.$set(
                            _vm.billing,
                            "name",
                            typeof $$v === "string" ? $$v.trim() : $$v
                          )
                        },
                        expression: "billing.name"
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "form-group" },
                  [
                    _c("label", { staticClass: "form-label" }, [
                      _vm._v(_vm._s(_vm.$t("customers.state")))
                    ]),
                    _vm._v(" "),
                    _c("base-input", {
                      attrs: { name: "billing.state", type: "text" },
                      model: {
                        value: _vm.billing.state,
                        callback: function($$v) {
                          _vm.$set(_vm.billing, "state", $$v)
                        },
                        expression: "billing.state"
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "form-group" },
                  [
                    _c("label", { staticClass: "form-label" }, [
                      _vm._v(_vm._s(_vm.$t("customers.address")))
                    ]),
                    _vm._v(" "),
                    _c("base-text-area", {
                      attrs: {
                        tabindex: 11,
                        placeholder: _vm.$t("general.street_1"),
                        type: "text",
                        name: "billing_street1",
                        rows: "2"
                      },
                      on: {
                        input: function($event) {
                          return _vm.$v.billing.address_street_1.$touch()
                        }
                      },
                      model: {
                        value: _vm.billing.address_street_1,
                        callback: function($$v) {
                          _vm.$set(
                            _vm.billing,
                            "address_street_1",
                            typeof $$v === "string" ? $$v.trim() : $$v
                          )
                        },
                        expression: "billing.address_street_1"
                      }
                    }),
                    _vm._v(" "),
                    _vm.$v.billing.address_street_1.$error
                      ? _c("div", [
                          !_vm.$v.billing.address_street_1.maxLength
                            ? _c("span", { staticClass: "text-danger" }, [
                                _vm._v(
                                  _vm._s(_vm.$t("validation.address_maxlength"))
                                )
                              ])
                            : _vm._e()
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _c("base-text-area", {
                      attrs: {
                        tabindex: 12,
                        placeholder: _vm.$t("general.street_2"),
                        type: "text",
                        name: "billing_street2",
                        rows: "2"
                      },
                      on: {
                        input: function($event) {
                          return _vm.$v.billing.address_street_2.$touch()
                        }
                      },
                      model: {
                        value: _vm.billing.address_street_2,
                        callback: function($$v) {
                          _vm.$set(
                            _vm.billing,
                            "address_street_2",
                            typeof $$v === "string" ? $$v.trim() : $$v
                          )
                        },
                        expression: "billing.address_street_2"
                      }
                    }),
                    _vm._v(" "),
                    _vm.$v.billing.address_street_2.$error
                      ? _c("div", [
                          !_vm.$v.billing.address_street_2.maxLength
                            ? _c("span", { staticClass: "text-danger" }, [
                                _vm._v(
                                  _vm._s(_vm.$t("validation.address_maxlength"))
                                )
                              ])
                            : _vm._e()
                        ])
                      : _vm._e()
                  ],
                  1
                )
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-sm-5" }, [
                _c(
                  "div",
                  { staticClass: "form-group" },
                  [
                    _c("label", { staticClass: "form-label" }, [
                      _vm._v(_vm._s(_vm.$t("customers.country")))
                    ]),
                    _vm._v(" "),
                    _c("base-select", {
                      attrs: {
                        options: _vm.billingCountries,
                        searchable: true,
                        "show-labels": false,
                        "allow-empty": true,
                        tabindex: 8,
                        placeholder: _vm.$t("general.select_country"),
                        label: "name",
                        "track-by": "id"
                      },
                      model: {
                        value: _vm.billing_country,
                        callback: function($$v) {
                          _vm.billing_country = $$v
                        },
                        expression: "billing_country"
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "form-group" },
                  [
                    _c("label", { staticClass: "form-label" }, [
                      _vm._v(_vm._s(_vm.$t("customers.city")))
                    ]),
                    _vm._v(" "),
                    _c("base-input", {
                      attrs: { name: "billing.city", type: "text" },
                      model: {
                        value: _vm.billing.city,
                        callback: function($$v) {
                          _vm.$set(_vm.billing, "city", $$v)
                        },
                        expression: "billing.city"
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "form-group" },
                  [
                    _c("label", { staticClass: "form-label" }, [
                      _vm._v(_vm._s(_vm.$t("customers.phone")))
                    ]),
                    _vm._v(" "),
                    _c("base-input", {
                      attrs: { type: "text", name: "phone", "tab-index": "13" },
                      model: {
                        value: _vm.billing.phone,
                        callback: function($$v) {
                          _vm.$set(
                            _vm.billing,
                            "phone",
                            typeof $$v === "string" ? $$v.trim() : $$v
                          )
                        },
                        expression: "billing.phone"
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "form-group" },
                  [
                    _c("label", { staticClass: "form-label" }, [
                      _vm._v(_vm._s(_vm.$t("customers.zip_code")))
                    ]),
                    _vm._v(" "),
                    _c("base-input", {
                      attrs: { type: "text", name: "zip", "tab-index": "14" },
                      model: {
                        value: _vm.billing.zip,
                        callback: function($$v) {
                          _vm.$set(
                            _vm.billing,
                            "zip",
                            typeof $$v === "string" ? $$v.trim() : $$v
                          )
                        },
                        expression: "billing.zip"
                      }
                    })
                  ],
                  1
                )
              ])
            ]),
            _vm._v(" "),
            _c("hr"),
            _vm._v(" "),
            _c("div", { staticClass: "same-address-checkbox-container" }, [
              _c(
                "div",
                { staticClass: "p-1" },
                [
                  _c(
                    "base-button",
                    {
                      ref: "sameAddress",
                      staticClass: "btn-sm",
                      attrs: { icon: "copy", color: "theme" },
                      on: {
                        click: function($event) {
                          return _vm.copyAddress(true)
                        }
                      }
                    },
                    [
                      _vm._v(
                        "\n              " +
                          _vm._s(_vm.$t("customers.copy_billing_address")) +
                          "\n            "
                      )
                    ]
                  )
                ],
                1
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "section-title col-sm-2" }, [
                _vm._v(
                  "\n            " +
                    _vm._s(_vm.$t("customers.shipping_address")) +
                    "\n          "
                )
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-sm-5" }, [
                _c(
                  "div",
                  { staticClass: "form-group" },
                  [
                    _c("label", { staticClass: "form-label" }, [
                      _vm._v(_vm._s(_vm.$t("customers.name")))
                    ]),
                    _vm._v(" "),
                    _c("base-input", {
                      attrs: {
                        type: "text",
                        name: "address_name",
                        "tab-index": "15"
                      },
                      model: {
                        value: _vm.shipping.name,
                        callback: function($$v) {
                          _vm.$set(
                            _vm.shipping,
                            "name",
                            typeof $$v === "string" ? $$v.trim() : $$v
                          )
                        },
                        expression: "shipping.name"
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "form-group" },
                  [
                    _c("label", { staticClass: "form-label" }, [
                      _vm._v(_vm._s(_vm.$t("customers.state")))
                    ]),
                    _vm._v(" "),
                    _c("base-input", {
                      attrs: { name: "shipping.state", type: "text" },
                      model: {
                        value: _vm.shipping.state,
                        callback: function($$v) {
                          _vm.$set(_vm.shipping, "state", $$v)
                        },
                        expression: "shipping.state"
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "form-group" },
                  [
                    _c("label", { staticClass: "form-label" }, [
                      _vm._v(_vm._s(_vm.$t("customers.address")))
                    ]),
                    _vm._v(" "),
                    _c("base-text-area", {
                      attrs: {
                        tabindex: 19,
                        placeholder: _vm.$t("general.street_1"),
                        type: "text",
                        name: "street_1",
                        rows: "2"
                      },
                      on: {
                        input: function($event) {
                          return _vm.$v.shipping.address_street_1.$touch()
                        }
                      },
                      model: {
                        value: _vm.shipping.address_street_1,
                        callback: function($$v) {
                          _vm.$set(
                            _vm.shipping,
                            "address_street_1",
                            typeof $$v === "string" ? $$v.trim() : $$v
                          )
                        },
                        expression: "shipping.address_street_1"
                      }
                    }),
                    _vm._v(" "),
                    _vm.$v.shipping.address_street_1.$error
                      ? _c("div", [
                          !_vm.$v.shipping.address_street_1.maxLength
                            ? _c("span", { staticClass: "text-danger" }, [
                                _vm._v(
                                  _vm._s(_vm.$t("validation.address_maxlength"))
                                )
                              ])
                            : _vm._e()
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _c("base-text-area", {
                      attrs: {
                        tabindex: 20,
                        placeholder: _vm.$t("general.street_2"),
                        type: "text",
                        name: "street_2",
                        rows: "2"
                      },
                      on: {
                        input: function($event) {
                          return _vm.$v.shipping.address_street_2.$touch()
                        }
                      },
                      model: {
                        value: _vm.shipping.address_street_2,
                        callback: function($$v) {
                          _vm.$set(
                            _vm.shipping,
                            "address_street_2",
                            typeof $$v === "string" ? $$v.trim() : $$v
                          )
                        },
                        expression: "shipping.address_street_2"
                      }
                    }),
                    _vm._v(" "),
                    _vm.$v.shipping.address_street_2.$error
                      ? _c("div", [
                          !_vm.$v.shipping.address_street_2.maxLength
                            ? _c("span", { staticClass: "text-danger" }, [
                                _vm._v(
                                  _vm._s(_vm.$t("validation.address_maxlength"))
                                )
                              ])
                            : _vm._e()
                        ])
                      : _vm._e()
                  ],
                  1
                )
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-sm-5" }, [
                _c(
                  "div",
                  { staticClass: "form-group" },
                  [
                    _c("label", { staticClass: "form-label" }, [
                      _vm._v(_vm._s(_vm.$t("customers.country")))
                    ]),
                    _vm._v(" "),
                    _c("base-select", {
                      attrs: {
                        options: _vm.shippingCountries,
                        searchable: true,
                        "show-labels": false,
                        tabindex: 16,
                        "allow-empty": true,
                        placeholder: _vm.$t("general.select_country"),
                        label: "name",
                        "track-by": "id"
                      },
                      model: {
                        value: _vm.shipping_country,
                        callback: function($$v) {
                          _vm.shipping_country = $$v
                        },
                        expression: "shipping_country"
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "form-group" },
                  [
                    _c("label", { staticClass: "form-label" }, [
                      _vm._v(_vm._s(_vm.$t("customers.city")))
                    ]),
                    _vm._v(" "),
                    _c("base-input", {
                      attrs: { name: "shipping.city", type: "text" },
                      model: {
                        value: _vm.shipping.city,
                        callback: function($$v) {
                          _vm.$set(_vm.shipping, "city", $$v)
                        },
                        expression: "shipping.city"
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "form-group" },
                  [
                    _c("label", { staticClass: "form-label" }, [
                      _vm._v(_vm._s(_vm.$t("customers.phone")))
                    ]),
                    _vm._v(" "),
                    _c("base-input", {
                      attrs: { type: "text", name: "phone", "tab-index": "21" },
                      model: {
                        value: _vm.shipping.phone,
                        callback: function($$v) {
                          _vm.$set(
                            _vm.shipping,
                            "phone",
                            typeof $$v === "string" ? $$v.trim() : $$v
                          )
                        },
                        expression: "shipping.phone"
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "form-group" },
                  [
                    _c("label", { staticClass: "form-label" }, [
                      _vm._v(_vm._s(_vm.$t("customers.zip_code")))
                    ]),
                    _vm._v(" "),
                    _c("base-input", {
                      attrs: { type: "text", name: "zip", "tab-index": "22" },
                      model: {
                        value: _vm.shipping.zip,
                        callback: function($$v) {
                          _vm.$set(
                            _vm.shipping,
                            "zip",
                            typeof $$v === "string" ? $$v.trim() : $$v
                          )
                        },
                        expression: "shipping.zip"
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "form-group collapse-button-container" },
                  [
                    _c(
                      "base-button",
                      {
                        staticClass: "collapse-button",
                        attrs: {
                          tabindex: 23,
                          icon: "save",
                          color: "theme",
                          type: "submit"
                        }
                      },
                      [
                        _vm._v(
                          "\n                " +
                            _vm._s(_vm.$t("customers.save_customer")) +
                            "\n              "
                        )
                      ]
                    )
                  ],
                  1
                )
              ])
            ])
          ])
        ])
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
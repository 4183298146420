var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "wizard" }, [
    _c("div", { staticClass: "step-indicator" }, [
      _c("img", {
        staticClass: "logo-main",
        attrs: {
          id: "logo-crater",
          src: "/assets/img/crater-logo.png",
          alt: "Crater Logo"
        }
      }),
      _vm._v(" "),
      _c("div", { staticClass: "indicator-line" }, [
        _c("div", { staticClass: "center" }, [
          _c(
            "div",
            {
              staticClass: "steps",
              class: { active: _vm.step === 1, completed: _vm.step > 1 }
            },
            [
              _vm.step > 1
                ? _c("font-awesome-icon", {
                    staticClass: "icon-check",
                    attrs: { icon: "check" }
                  })
                : _vm._e()
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "steps",
              class: { active: _vm.step === 2, completed: _vm.step > 2 }
            },
            [
              _vm.step > 2
                ? _c("font-awesome-icon", {
                    staticClass: "icon-check",
                    attrs: { icon: "check" }
                  })
                : _vm._e()
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "steps",
              class: { active: _vm.step === 3, completed: _vm.step > 3 }
            },
            [
              _vm.step > 3
                ? _c("font-awesome-icon", {
                    staticClass: "icon-check",
                    attrs: { icon: "check" }
                  })
                : _vm._e()
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "steps",
              class: { active: _vm.step === 4, completed: _vm.step > 4 }
            },
            [
              _vm.step > 4
                ? _c("font-awesome-icon", {
                    staticClass: "icon-check",
                    attrs: { icon: "check" }
                  })
                : _vm._e()
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "steps",
              class: { active: _vm.step === 5, completed: _vm.step > 5 }
            },
            [
              _vm.step > 5
                ? _c("font-awesome-icon", {
                    staticClass: "icon-check",
                    attrs: { icon: "check" }
                  })
                : _vm._e()
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "steps",
              class: { active: _vm.step === 6, completed: _vm.step > 6 }
            },
            [
              _vm.step > 6
                ? _c("font-awesome-icon", {
                    staticClass: "icon-check",
                    attrs: { icon: "check" }
                  })
                : _vm._e()
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "steps",
              class: { active: _vm.step === 7, completed: _vm.step > 7 }
            },
            [
              _vm.step > 7
                ? _c("font-awesome-icon", {
                    staticClass: "icon-check",
                    attrs: { icon: "check" }
                  })
                : _vm._e()
            ],
            1
          )
        ])
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "form-content" }, [
      _c(
        "div",
        { staticClass: "card wizard-card" },
        [_c(_vm.tab, { tag: "component", on: { next: _vm.setTab } })],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }